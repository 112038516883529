<template>
  <v-col cols="12" sm="11" md="10" lg="7" class="mx-auto main-col-container"  v-if="$store.state.auth.user._id">
    <v-card-title class="pt-0 mt-5">
      <v-icon>mdi-chevron-down</v-icon> Vos annonces publiées ({{ $store.state.auth.user.offers.length }})
    </v-card-title>
    
    <v-col cols="12" class="py-1" v-if="$store.state.auth.user.offers.length == 0">
      <v-card-subtitle class="pt-0">
        Vous n'avez publié aucune annonce
      </v-card-subtitle>
    </v-col>
    
    <v-row v-if="$store.state.auth.user.offers.length > 0">
      <v-col cols="12" sm="6" md="6" class="pt-1 px-0" 
             v-for="offer in $store.state.auth.user.offers" :key="offer._id">
        <v-card class="mt-2 pt-1 mx-3 hide-gallery" outlined elevation="0">
          <OfferCard class="px-1" :offer="offer"></OfferCard>
          <v-toolbar dense color="" elevation="0">
            
            <v-spacer></v-spacer>
            <v-btn small text color="red" class="mr-2"
                  @click="showDeleteOfferObj(offer)">
              <v-icon small color="">mdi-delete</v-icon> supprimer
            </v-btn>
            <!-- <v-btn x-small text elevation="0"  class="mr-0" 
                   title="afficher l'annonce" :to="'/offer/'+offer._id">
              <v-icon small color="">mdi-eye</v-icon> Lire
            </v-btn> -->
            <!-- <v-btn x-small fab outlined color="" class="mr-3 d-sm-none" 
                  :to="'/edit-offer/' + offer._id">
              <v-icon small color="">mdi-pencil</v-icon>
            </v-btn> -->
            <v-btn small text color="orange" class="mr3" 
                  :to="'/edit-offer/' + offer._id">
              <v-icon x-small class="mr-1">mdi-pencil</v-icon> Modifier
            </v-btn>
          </v-toolbar>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="showDeleteOffer" max-width="500">
      <v-card v-if="deleteOfferObj != null" style="border:3px solid #f44336">
        <v-toolbar color="transparent" elevation="0">
          <v-icon small class="pr-1">mdi-delete</v-icon> Voulez vous vraiment supprimer cette annonce ?
          <v-spacer/>
          <v-btn fab color="transparent" elevation="0" small @click="hideDeleteOfferObj">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-col cols="12" class="py-0" v-if="deleteOfferObj != false">
          <b class="pl-5 ml-1">
            {{ deleteOfferObj.title.substr(0, 60) }}
            {{ deleteOfferObj.title.length > 60 ? "..." : "" }}
          </b>
          <b class="primary--text font-led pl-1"> • 
            {{ deleteOfferObj.amountMony }} 
            {{ $store.state.app.monyUnity }}
          </b>
        </v-col>
        <v-toolbar color="transparent" elevation="0">
          <v-spacer/>
          <v-btn elevation="0" @click="hideDeleteOfferObj" class="mr-3">Annuler</v-btn>
          <v-btn elevation="0" color="red" dark @click="deleteOffer(deleteOfferObj)">Oui, supprimer</v-btn>
        </v-toolbar>
      </v-card>
    </v-dialog>

    <v-row>
      <v-col cols="12" class="py-1 px-0 pt-5 mb-5 pb-5">
        <v-toolbar color="transparent" elevation="0" dense>
          <v-spacer/>
          <v-btn rounded color="orange" to="/publish-offer" outlined
                :disabled="!$store.state.auth.user.isActive">
            <v-icon small class="mr-1">mdi-pencil</v-icon> 
            Publier une annonce
          </v-btn>
        </v-toolbar>
      </v-col>
    </v-row>
    <OfferDialog></OfferDialog>
  </v-col>
</template>

<script>
import core from '../../plugins/core.js'
import OfferCard from '@/components/offer/OfferCardSM.vue'
import OfferDialog from '@/components/offer/OfferDialog.vue'

import axios from "axios"

export default {
  name: 'home',
  components: {
    OfferCard, OfferDialog
  },
  data: () => ({
    deleteOfferObj: false,
    showDeleteOffer: false,
    deleteOfferLoading: false,
    errorDeleteOffer: false,
    errorDeleteOfferMsg: '',
  }),
  mounted: async function(){
  },
  methods: {
    nl2br: function(str){
      var breakTag = '<br>'
      let newStr = (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2')
      let maxLength = 60
      let p = newStr.length > maxLength ? '...' : ''
      newStr = newStr.substr(0, maxLength) + p
      return newStr
    },
    showDeleteOfferObj: function(offer){
      this.deleteOfferObj = offer
      this.showDeleteOffer = true
    },
    hideDeleteOfferObj: function(){
      this.deleteOfferObj = null
      this.showDeleteOffer = false
    },
    deleteOffer: async function(offer){
      //console.log("deleteOffer")
      this.errorDeleteOfferMsg = ''
      this.errorDeleteOffer = false
      this.deleteOfferLoading = true 

      //console.log("try to delete offer", offer._id)
      axios.post('/market/delete-offer/'+offer._id)
            .then((res)=>{
              this.loading = false 
              //console.log("res /market/delete-offer/"+offer._id, res)
              if(res.data.error == false){
                console.log("OFFER DELETED WITH SUCCES", res.data)
                this.showDeleteOffer = false
                this.$store.dispatch('auth/refreshUserData')
                this.$store.dispatch('app/openSnackbar', 
                              { show: true, color:'green', 
                                title: "Votre annonce a bien été supprimée",
                                text: '',
                                icon: 'thumb-up' })
              }else{
                this.$store.dispatch('app/openSnackbar', 
                              { show: true, color:'green', 
                                title: "Une erreur est survenue pendant la suppression de l'annonce",
                                text: res.data.msg,
                                icon: 'thumb-up' })
              }    
            })

    },
    baseUrl(){ return core.baseUrl() },
  },
  computed: {
  },
  watch: {
      
  }, 
}
</script>